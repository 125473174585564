import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HomeService } from './home.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  lat = 51.678418;
  lng = 7.809007;
  constructor(private fb: FormBuilder, private homeServive: HomeService) { }

  ngOnInit() {
  }

  addAppointment = this.fb.group({
    fullName: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email:  ['',[Validators.required]],
    age: ['', [Validators.required]],
    preferredDate: ['', [Validators.required]],
    message: ['', [Validators.required]],
  })

  submit() {
    this.homeServive.addAppointment(this.addAppointment.value).subscribe(() => { });
    console.log(this.addAppointment.value);
  }

}
