import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { ToastrService } from 'ngx-toastr';
import { AddPatientService } from './add-patient.service';
@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddPatientComponent),
      multi: true,
    },
  ],
})
export class AddPatientComponent implements OnInit,ControlValueAccessor {
  @ViewChild ('signatureCanvas', {static: true}) signaturePad: SignaturePad;
  public options: Object = {};
  public _signature: any = null;
  public propagateChange: Function = null;
  get signature(): any {
    return this._signature;
  }

  constructor(private fb: FormBuilder, private patientService: AddPatientService, private toastr: ToastrService ) { }

  ngOnInit(): void {
  }

  addPatient = this.fb.group({
    fullName: ['',[Validators.required]],
    dob: ['', [Validators.required]],
    diagnosis: [''],
    referred_by_dr: [''],
    phone: [''],
    gender: [''],
    weight: [''],
    fee: ['',[Validators.required]],
    //email: [''],
    treatment: ['',[Validators.required]],
    //ergonomic_advice: [''],
  });

  get fullName(){
    return this.addPatient.get('fullName');
  }

  get dob(){
    return this.addPatient.get('dob');
  }

  get fee(){
    return this.addPatient.get('fee');
  }

  get treatment(){
    return this.addPatient.get('treatment');
  }

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 415,
    'canvasHeight': 150
  };

  set signature(value: any) {
    this._signature = value;
    console.log('set signature to ' + this._signature);
    console.log('signature data :');
    console.log(this.signaturePad.toData());
    this.propagateChange(this.signature);
  }

  canvasResize() {
    const canvas = document.querySelector('canvas');
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.set('canvasWidth', canvas.offsetWidth);
    this.signaturePad.set('canvasHeigth', canvas.offsetHeight);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  public ngAfterViewInit(): void {
    this.signaturePad.clear();
  }

  public drawStart(): void {
    console.log('Begin Drawing');
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL();
  }

  clearPad(){
    this.signaturePad.clear();
  }
 
  onSubmit() {
    console.log(this.addPatient.value);
    console.log(this.signature);
    this.patientService.addPatient(this.addPatient.value).subscribe((res) => {
      if(res['status']===200){
        this.toastr.success('Added Patient Successfully','Success');
        this.addPatient.reset();
      }
      else{
        this.toastr.error(res['message'],'Error');
      }
    });
  }

}
