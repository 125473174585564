import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminLoginService } from './admin-login.service';
import jwt_decode from "jwt-decode";
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  constructor(private fb: FormBuilder, private router: Router, private adminS: AdminLoginService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  loginForm = this.fb.group({
    phone: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
  });

  get phone() {
    return this.loginForm.get('phone');
  }

  get password() {
    return this.loginForm.get('password');
  }
  token;
  token1: string;
  onSubmit() {
    this.adminS.adminLogin(this.loginForm.value).subscribe((res) => {
      if (res['status'] === 400) {
        this.toastr.error(res['message'], 'Error');
      }
      else {
        this.token1 = jwt_decode(res['token']);
        localStorage.setItem("token", res['token']);
        if (this.token1['isAdmin'] == true) {
          this.router.navigate(['/Patient-Portal']);
          this.toastr.success('Dr.Radhika Bajaj', 'Welcome');
        }
      }

    });

  }

}
