import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BASE_URL} from '../shared/constants/url';

@Injectable({
  providedIn: 'root'
})
export class AdminLoginService {

  constructor(private http: HttpClient) { }

  adminLogin(payload) {
    var url = `${BASE_URL}/login`;
    return this.http.post(url, payload);
  }
}
