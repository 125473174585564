<!-- Material form login -->
<div class="container-fluid">

  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <div class="card">

        <h5 class="card-header info-color white-text text-center py-4">
          <strong>Admin Login</strong>
        </h5>

        <!--Card content-->
        <div class="card-body px-lg-5 pt-0">

          <!-- Form -->
          <form class="text-center" style="color: #757575;" action="#!" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

            <!-- Email -->
            <div class="md-form">
              <!-- <input type="email" formControlName="email" placeholder="Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control mail"> -->
                <input type="number" formControlName="phone" class="form-control mail" placeholder="Phone Number">
              <div *ngIf="phone.invalid && phone.touched">
                <small *ngIf="phone.errors?.required" class="text-danger">Phone Number is Required.</small>
                <!-- <small *ngIf="email.errors?.pattern" class="text-danger">Email is not in correct pattern.</small> -->
              </div>
            </div>

            <!-- Password -->
            <div class="md-form">
              <input type="password" id="password" class="form-control mail" placeholder="Password"
                formControlName="password">
              <div *ngIf=" password.invalid && password.touched">
                <small *ngIf="password.errors?.required" class="text-danger">Password is required</small>
                <small *ngIf="password.errors?.minlength" class="text-danger">Password length must be 8</small>
                <small *ngIf="password.errors?.maxlength" class="text-danger">Password length must be less than
                  20</small>
              </div>
            </div>

            <!-- Sign in button -->
            <button [disabled]='!loginForm.valid'
              class="button" type="submit">Sign
              in</button>


          </form>
          <!-- Form -->

        </div>

      </div>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
<!-- Material form login -->