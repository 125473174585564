<form [formGroup]="addPatient" (ngSubmit)="onSubmit()">
    <!-- <div class="row">
        <input type="text" placeholder="Patient Name*" formControlName="fullName">
        <input type="date" placeholder="DOB*" formControlName="dob">
        <input type="text" placeholder="Diagnosis" formControlName="diagnosis">

        <input type="text" placeholder="Refered by Dr." formControlName="referred_by_dr">
    </div>
    <div class="form-error" *ngIf="fullName.invalid && fullName.touched">
        <small *ngIf="fullName.errors?.required" class="text-danger">Patient Name is Required.</small>
    </div>
    <div class="form-error" *ngIf="dob.invalid && dob.touched">
        <small *ngIf="dob.errors?.required" class="text-danger">Date is Required.</small>
    </div>

    <div class="row">
        <select formControlName="gender">
            <option selected="default">Gender</option>
            <option>Male</option>
            <option>Female</option>
        </select>
        <input type="text" placeholder="Phone" formControlName="phone">
        <input type="number" placeholder="Weight" formControlName="weight">
        <input type="number" placeholder="Fees*" formControlName="fee">
        <input type="text" placeholder="Email" formControlName="email">
    </div>
    <div class="form-error" *ngIf="fee.invalid && fee.touched">
        <small *ngIf="fee.errors?.required" class="text-danger">Fees is Required.</small>
    </div>
    <div class="row">
        <textarea placeholder="Treatment*" formControlName="treatment"></textarea>
        <textarea placeholder="Ergonomic Advice" formControlName="ergonomic_advice"></textarea>
    </div>
    <div class="form-error" *ngIf="treatment.invalid && treatment.touched">
        <small *ngIf="treatment.errors?.required" class="text-danger">Treatment is Required.</small>
    </div>
    <div class="row">
        <div class="sign-pad">
            <signature-pad #signatureCanvas [options]="signaturePadOptions" id="signatureCanvas"
                (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"> </signature-pad>
        </div>
    </div>

    <div style="text-align: center;">
        <button class="button" type="submit" [disabled]='!addPatient.valid'>Add Patient</button>
    </div> -->
    <div class="row">
        <div class="col-sm-6">
            <div class="row">
                <label class="label">Patient Name: </label>
                <input type="text" formControlName="fullName" name="fullName" />
            </div>
            <div class="row">
                <label class="label">Date: </label>
                <input type="date" formControlName="dob" name="dob" />
            </div>
            <!-- <div class="row">
                <label class="label">Email: </label>
                <input type="text" [(ngModel)]="patientData.email" name="email" />
            </div> -->
            <div class="row">
                <label class="label">Phone: </label>
                <input type="text" formControlName="phone" name="phone" />
            </div>
            <div class="row">
                <label class="label">Gender: </label>
                <select formControlName="gender">
                    <option value="">Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                </select>
            </div>
            <div class="row">
                <label class="label">Diagnosis: </label>
                <input type="text" formControlName="diagnosis" name="diagnosis" />
            </div>
           
        </div>
        <div class="col-sm-6">
            <div class="row">
                <label class="label">Weight: </label>
                <input type="number" formControlName="weight" name="weight" />
            </div>
            <div class="row">
                <label class="label">Fees: </label>
                <input type="number" formControlName="fee" name="fee" />
            </div>
            <div class="row">
                <label class="label">Referred By Dr: </label>
                <input type="text" formControlName="referred_by_dr" name="referred_by_dr" />
            </div>
            <div>
                <label class="label">Treatment: </label>
            </div>
            <div>
                <textarea type="text" formControlName="treatment" name="treatment"></textarea>
            </div>
        </div>
    </div>
    <div class="second-row">
        <div class="sign-pad">
            <signature-pad #signatureCanvas [options]="signaturePadOptions" id="signatureCanvas"
                (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"> </signature-pad>
        </div>
    
        <div style="text-align: center;">
            <button class="button" type="submit" [disabled]='!addPatient.valid'>Add Patient</button>
        </div> 
    </div>
    
</form>