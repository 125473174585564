<div class="tab-container">
    <div class="tabs">
        <div class="tab" [ngClass]="{ selected: selected === 1 }" (click)="setSelected(1)">
            ADD PATIENT
        </div>
        <div class="tab" [ngClass]="{ selected: selected === 2 }" (click)="setSelected(2)">
            APPOINTMENTS
        </div>
        <div class="tab" [ngClass]="{ selected: selected === 3 }" (click)="setSelected(3)">
            PATIENTS-LIST
        </div>
        <div class="tab" [ngClass]="{ selected: selected === 4 }" (click)="setSelected(4)">
            short payments
        </div>
    </div>
    <div>
        <button class="button" (click)="logout()">LOGOUT</button>
    </div>

    <div class="form">
        <div *ngIf="selected === 1">
            <app-add-patient></app-add-patient>
        </div>
        <div *ngIf="selected === 2">
            <app-appointment></app-appointment>
        </div>
        <div *ngIf="selected === 3">
            <app-patient-list></app-patient-list>
        </div>
    </div>
</div>