<div *ngIf="selected === 1">
    <table class="table">
        <thead>
            <th>Sr.No.</th>
            <th>Full Name</th>
            <th>Date</th>
            <th>Gender</th>
            <th>Phone</th>
            <!-- <th>Email</th> -->
            <th>Weight</th>
            <th>Fees</th>
            <!-- <th>Action</th> -->
        </thead>
        <tbody>
            <ng-container *ngFor="let item of patientList; let i=index">
                <tr *ngIf="item.isAdmin == false">
                    <td>{{i + 1 - 1}}</td>
                    <td (click)="viewProfile(item)" class="name-pointer">{{item.fullName}}</td>
                    <td>{{item.dob | date: "dd-MM-yyyy"}}</td>
                    <td>{{item.gender}}</td>
                    <td>{{item.phone}}</td>
                    <!-- <td>{{item.email}}</td> -->
                    <td>{{item.weight}} kg</td>
                    <td>{{item.fee}} rs.</td>
                    <!-- <button class="button" (click)="viewProfile(item)">View Profile</button> -->
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<div *ngIf="selected === 2">
    <form>
        <div class="row">
            <div class="col-sm-6">
                <div class="row second">
                    <label class="label">Patient Name: </label>
                    <input type="text" [(ngModel)]="patientData.fullName" name="fullName" />
                </div>
                <!-- <div class="row">
                    <label class="label">Date: </label>
                    <input type="date" [(ngModel)]="patientData.dob" name="dob" />
                </div> -->
                <!-- <div class="row">
                    <label class="label">Email: </label>
                    <input type="text" [(ngModel)]="patientData.email" name="email" />
                </div> -->
                <div class="row">
                    <label class="label">Phone: </label>
                    <input type="text" [(ngModel)]="patientData.phone" name="phone" />
                </div>
                <div class="row">
                    <label class="label">Gender: </label>
                    <input type="text" [(ngModel)]="patientData.gender" name="gender" />
                </div>
                <div class="row">
                    <label class="label">Weight: </label>
                    <input type="text" [(ngModel)]="patientData.weight" name="weight" />
                </div>
                <div class="row">
                    <label class="label">Fees: </label>
                    <input type="text" [(ngModel)]="patientData.fee" name="fee" />
                </div>
            </div>
            <div class=" col-sm-6">
                <div class="back-button">
                    <button class="button" (click)="back()">Back</button>
                </div>
                <div class="row second">
                    <label class="label">Diagnosis: </label>
                    <input type="text" [(ngModel)]="patientData.diagnosis" name="diagnosis" />
                </div>
                <div class="row">
                    <label class="label">Referred By Dr: </label>
                    <input type="text" [(ngModel)]="patientData.referred_by_dr" name="referred_by_dr" />
                </div>
                <div>
                    <label>Treatment: </label>
                </div>
                <div>
                    <textarea type="text" [(ngModel)]="patientData.treatment" name="treatment"></textarea>
                </div>
                <!-- <div>
                    <label class="label">Ergonomic Advice: </label>
                </div>
                <div>
                    <textarea [(ngModel)]="patientData.ergonomic_advice" name="ergonomic_advice"></textarea>
                </div> -->
                <div class="row">
                    <button class="action_button" (click)="updatePatient()">Update</button>
                    <button class="action_button delete" (click)="deletePatient(patientData._id)">Delete</button>
                </div>
            </div>
        </div>
    </form>
</div>