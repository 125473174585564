import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from './appointment.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {
  receivedAppointment;
  constructor(private appointmentS: AppointmentService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAppointments();
  }

  getAppointments() {
    this.appointmentS.getAppointments().subscribe((res) => {
      this.receivedAppointment = res;
    });
  }

  deleteAppointment(id) {
    this.appointmentS.deleteAppointment(id).subscribe((res) => {
      if(res['status'] == 200){
        this.toastr.success('Appointment Deleted','Success');
        this.getAppointments();
      }
    });
  }

}
