import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-patient-portal',
  templateUrl: './patient-portal.component.html',
  styleUrls: ['./patient-portal.component.css']
})
export class PatientPortalComponent implements OnInit {

  selected = 1;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  setSelected(val) {
    this.selected = val;
  }

  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['/Admin-Login']);
  }
}
