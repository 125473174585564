<!--banner-->
<section class="banner">
  <div class="overlay overlay-bg">
    <div class="banner-content col-12 justify-content-center">
      <!-- <h6 class="heading-1">DON’T LOOK FURTHER, THIS IS YOUR PHYSIOTHERAPIST </h6>
      <h1 class="heading-2">Gowardhan's Physiotherapy & Rehabilitation Center</h1> -->
    </div>
  </div>
</section>

<!-- Start open-hour Area -->
<section class="open-hour-area" id="address">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 open-hour-wrap">
        <h1>Address</h1>
        <div class="date-list d-flex flex-row justify-content-center">
          <p class="colm-1">Gowardhan physiotherapy clinic and Rehabilitation center,<br>
            First floor of Dr.D.G.Indani hospital,<br>
            Doctor line, Samta colony, Majalgaon <br>
            District : Beed, Pincode: 431131<br>
            State : Maharashtra</p>
        </div>
        <h1>Opening Hours</h1>
        <div class="date-list d-flex flex-row justify-content-center">
          <ul class="colm-1">
            <li>Monday - Sunday</li>
          </ul>
          <ul class="colm-2">
            <li><span>:</span> 10:00am to 03:00pm</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End open-hour Area -->

<!--Services-->
<section class="service-area section-gap" id="services">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 pb-40 header-text text-center">
        <h1 class="pb-10">What we deliver to our patients</h1>
        <p>
          Who are in extremely love with eco friendly system.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="single-service">
          <img class="img-fluid" src="../../assets/img/p1.jpeg" alt="">
          <h5>Traction For cervical spondylosis</h5>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="single-service">
          <img class="img-fluid" src="../../assets/img/p2.jpeg" alt="">
          <h5>Moist pack for relief of cervical pain</h5>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="single-service">
          <img class="img-fluid" src="../../assets/img/p3.jpeg" alt="">
          <h5>Ball exercises for strengthening of hand muscles</h5>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="single-service">
          <img class="img-fluid" src="../../assets/img/p4.jpeg" alt="">
          <h5>EMS for muscle stimulation to bring normal functioning</h5>
          <img class="img-fluid from-top" src="../../assets/img/p5.jpeg" alt="">
          <h5>Ultrasound for pain relief at tender points</h5>
          <img class="img-fluid from-top" src="../../assets/img/p6.jpeg" alt="">
          <h5>Weight loss machine</h5>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End service Area -->

<!--Contact us-->
<section class="home-about-area section-gap relative" id="contactUs">
  <div class="container">
    <div class=" align-items-center justify-content-end text-white">
      <h2 class="text-center">Why Physiotherapy is important?</h2>
      <h6 class="text-white" style="line-height: 25px; text-align: justify;">
        Physiotherapists help people affected by injury, illness or disability through movement and exercise, manual
        therapy, education and advice.
        They maintain health for people of all ages, helping patients to manage pain and prevent disease.
        The profession helps to encourage development and facilitate recovery, enabling people to stay in work while
        helping them remain independent for as long as possible.
      </h6>
      <hr style="background: white;">

      <!-- full-screen display with 3 columns -->
      <div class="full-screen">
        <h2><i class="fa fa-rocket"></i> Our Features</h2>
        <div class="row">
          <div class="col">
            <li>Wax Therapy</li>
            <li>Sports Injuries</li>
            <li>T.E.N.S Therapy</li>
            <li>Ultra sonic Therapy</li>
          </div>
          <div class="col">
            <li>Back Pain Complaints</li>
            <li>Interferential Therapy </li>
            <li>Neurological Complaints</li>
            <li>Electrical Spinal Traction</li>
          </div>
          <div class="col">
            <li>Electrical Muscle Simulation</li>
            <li>Arthritic/Orthopedic Complaints</li>
            <li>Well Equipped Exercise Therapy Unit</li>
          </div>
        </div>
      </div>

      <!-- small-screen display with 2 columns -->
      <div class="small-screen">
        <h2 class="text-center"><i class="fa fa-rocket"></i> Our Features </h2>
        <li>Wax Therapy</li>
        <li>Sports Injuries</li>
        <li>T.E.N.S Therapy</li>
        <li>Ultra sonic Therapy</li>
        <li>Interferential Therapy </li>
        <li>Neurological Complaints</li>
        <li>Electrical Spinal Traction</li>
        <li>Back Pain Complaints</li>
        <li>Electrical Muscle Simulation</li>
        <li>Arthritic/Orthopedic Complaints</li>
        <li>Well Equipped Exercise Therapy Unit</li>
      </div>
      <hr style="background: white;">

      <!-- full-screen display with 3 columns -->
      <div class="full-screen">
        <h2><i class="fa fa-thumbs-up"></i> सुविधा</h2>
        <div class="row">
          <div class="col">
            <li>संधिवात</li>
            <li>टाचदुखी</li>
            <li>मानदुखी</li>
            <li>पर्किन्सन्सचा आजार</li>
            <li>हात पाय बधिरता</li>
            <li>शस्त्रक्रियेनंतरचे व्यायाम व उपचार</li>
          </div>
          <div class="col">
            <li>खांदेदुखी</li>
            <li>पाठदुखी</li>
            <li>अर्धांगवायु</li>
            <li>मनका सरकने</li>
            <li>स्पोर्ट्स दुखापती</li>
            <li>मणक्याची गादी सरकने व पायाला मूंग्या येणे</li>
          </div>
          <div class="col">
            <li>कोपरदुखी</li>
            <li>गुडगेदुखी</li>
            <li>तोड़ वाकड़े होने</li>
            <li>फेशिअल पाल्सी</li>
            <li>सेरेब्रल पाल्सी</li>
            <li>छोट्यामुलांचे उशिरा उठने, बसने, चालने यावर उपचार</li>
          </div>
        </div>
      </div>

      <!-- small-screen display with 2 columns -->

      <div class="small-screen">
        <h2 class="text-center"><i class="fa fa-thumbs-up"></i> सुविधा</h2>

        <div class="row">
          <div class="col">
            <li>संधिवात</li>
            <li>टाचदुखी</li>
            <li>मानदुखी</li>
            <li>कोपरदुखी</li>
            <li>गुडगेदुखी</li>
            <li>सेरेब्रल पाल्सी</li>
            <li>पर्किन्सन्सचा आजार</li>
            <li>हात पाय बधिरता</li>
          </div>
          <div class="col">
            <li>खांदेदुखी</li>
            <li>पाठदुखी</li>
            <li>अर्धांगवायु</li>
            <li>तोड़ वाकड़े होने</li>
            <li>फेशिअल पाल्सी</li>
            <li>मनका सरकने</li>
            <li>स्पोर्ट्स दुखापती</li>
          </div>
        </div>
        <li>शस्त्रक्रियेनंतरचे व्यायाम व उपचार</li>
        <li>मणक्याची गादी सरकने व पायाला मूंग्या येणे</li>
        <li>छोट्यामुलांचे उशिरा उठने, बसने, चालने यावर उपचार</li>
      </div>
      <hr style="background: white;">

      <div class="full-screen">
        <h2><i class="fa fa-phone"></i> Contact Us</h2>
        <p>Dr.Radhika Rahul Bajaj (B.P.Th & Dietician) <br>
          Gowardhan Physiotherapy & Rehabilitation Center<br>
          Samta colony, Majalgaon, District : Beed<br>
          Contact No: <a href="tel:+917972579584" style="color: white;"> <i class="fa fa-whatsapp"></i> +91
            7972579584</a><br>
          <a href="mailto:radhikaladdha28@gmail.com?subject = contact&body = Message" style="color: white;"> <i
              class="fa fa-envelope"></i> radhikaladdha28@gmail.com</a>
        </p>
      </div>

      <div class="small-screen text-center">
        <h2><i class="fa fa-phone"></i> Contact Us</h2>
        <p>Dr.Radhika Rahul Bajaj (B.P.Th & Dietician) <br>
          Gowardhan Physiotherapy <br>& <br> Rehabilitation Center<br>
          Samta colony, Majalgaon, District : Beed<br>
          <a href="tel:+917972579584" style="color: white;"> <i class="fa fa-whatsapp"></i> +91 7972579584</a><br>
          <a href="mailto:radhikaladdha28@gmail.com?subject = contact&body = Message" style="color: white;"> <i
              class="fa fa-envelope"></i> radhikaladdha28@gmail.com</a>
        </p>
      </div>
    </div>
  </div>
</section>

<!-- <agm-map [latitude]="lat" [longitude]="lng">
  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
</agm-map> -->


<!-- Start appoinment Area -->
<section class="appoinment-area section-gap relative" id="appoinment">
  <div class="container">
    <div class="row align-items-center justify-content-end">
      <div class="col-lg-6 no-padding appoinment-right">
        <h1 class="text-white">
          Book an <br>
          Appoinment
        </h1>
        <form class="appoinment-form" [formGroup]="addAppointment" action="#">
          <div class="row">
            <div class="col-lg-12 d-flex flex-column">
              <input name="patient-name" formControlName="fullName" placeholder="Patient Name" onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Patient Name'" class="form-control mt-20" required="" type="text">
            </div>
            <div class="col-lg-6 d-flex flex-column">
              <input name="phone" formControlName="phone" placeholder="Phone" onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Phone'" class="form-control mt-20" required="" type="text">
            </div>
            <div class="col-lg-6 d-flex flex-column">
              <input name="email" formControlName="email" placeholder="Email address" onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Email address'" class="form-control mt-20" required="" type="email">
            </div>
            <div class="col-lg-6 d-flex flex-column">
              <input name="age" formControlName="age" placeholder="Age" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Age'"
                class="form-control mt-20" required="" type="text" id="datepicker">
            </div>
            <div class="col-lg-6 d-flex flex-column">
              <input name="appoinment-date" formControlName="preferredDate" placeholder="Appoinment date" onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Appoinment date'" class="form-control mt-20" required="" type="date"
                id="datepicker2">
            </div>
            <div class="col-lg-12 flex-column">
              <textarea rows="5" class="form-control mt-20" name="message" formControlName="message" placeholder="Messege"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Messege'" required=""></textarea>
            </div>

            <div class="col-lg-12 d-flex justify-content-end send-btn">
              <button class="primary-btn primary mt-20 text-uppercase" type="submit" (click)="submit()">Confirm booking</button>
            </div>

            <div class="alert-msg"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- End appoinment Area -->


<!--about us-->
<section class="service-area section-gap" id="aboutUs">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 pb-40 header-text text-center">
        <h1 class="pb-10">About Us</h1>
        <p>
          Website design and developed by
        </p>
      </div>
      <div class="col-lg-6 col-md-12 mb-r wow fadeInLeft">
        <div class="col-md-6 float-left"><img class="img-fluid rounded z-depth-1 mb-3" src="../../assets/img/swarup.jpg"
            alt="team member" /></div>
        <div class="col-md-6 float-right">
          <div class="h4 ">Swarup Laddha</div>
          <h6 class="font-bold blue-grey-text mb-4 ">Web Developer</h6>
          <p class="grey-text">Sometimes you wake up, Somestimes the fall kills you. And sometimes, when you fall, you
            fly.</p>

          <a href="mailto:swarup1510.sl@gmail.com?subject = contact&body = Message">
            <i class="fa fa-envelope"></i><span> swarup1510.sl@gmail.com</span>
          </a>
          <br>
          <a href="tel:+917972586172">
            <i class="fa fa-whatsapp"></i><span> +91 7972586172</span>
          </a>
        </div>
      </div>
    </div>
  </div>

</section>