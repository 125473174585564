<table class="table">
    <thead>
        <th>Sr.No.</th>
        <th>Full Name</th>
        <th>Age</th>
        <th>Phone</th>
        <th>Preferred Date</th>
        <th>Action</th>
    </thead>
    <tbody>
        <ng-container *ngFor="let item of receivedAppointment; let i=index">
            <tr>
                <td>{{i + 1}}</td>
                <td>{{item.fullName}}</td>
                <td>{{item.age}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.preferredDate}}</td>
                <button class="button" (click)=deleteAppointment(item._id)>Delete</button>
            </tr>
        </ng-container>
    </tbody>
</table>