import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'src/app/shared/constants/url';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpClient) { }

  getAppointments(){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': localStorage.getItem('token')
      })
    };
    var url = `${BASE_URL}/appointments`;
    return this.http.get(url,httpOptions);
  }

  deleteAppointment(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': localStorage.getItem('token')
      })
    };
    var url = `${BASE_URL}/appointments/${id}`;
    return this.http.delete(url,httpOptions);
  }
}
