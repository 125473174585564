import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BASE_URL} from '../../shared/constants/url';
@Injectable({
  providedIn: 'root'
})
export class AddPatientService {

  constructor(private http: HttpClient) { }

  addPatient(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': localStorage.getItem('token')
      })
    };
    var url = `${BASE_URL}/patients`;
    return this.http.post(url, payload,httpOptions);
  }
}
