import { Component, OnInit } from '@angular/core';
import { PatientListService } from './patient-list.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {
  patientList;
  patientInfo;
  selected = 1;

  patientData = {
    fullName: '',
    diagnosis: '',
    dob: '',
    email: '',
    ergonomic_advice: '',
    fee: '',
    gender: '',
    phone: '',
    referred_by_dr: '',
    treatment: '',
    weight: '',
    _id: '',
  }
  constructor(private patinetListS: PatientListService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPatients();
  }

  getPatients() {
    this.patinetListS.getPatients().subscribe((res) => {
      this.patientList = res['data'];
    })
  }

  viewProfile(item) {
    this.selected = 2;
    this.patientData = _.cloneDeep(item);
  }

  back() {
    this.selected = 1;
  }

  updatePatient() {
   this.patinetListS.updatePatient(this.patientData).subscribe((res)=>{
    if(res['status'] === 200){
      this.toastr.success('Patient Updated', 'Success');
      this.selected = 1;
      this.getPatients();
    }
    else{
      this.toastr.error(res['message'], 'Error');
    }
   });
  }

   deletePatient(id){
    this.patinetListS.deletePatient(id).subscribe((res)=>{
    if(res['status'] === 200){
      this.toastr.success('Patient Deleted', 'Success')
      this.selected = 1;
      this.getPatients();
    }
    else{
      this.toastr.error(res['message'], 'Error');
    }
    });
   }
  }
