import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'src/app/shared/constants/url';

@Injectable({
  providedIn: 'root'
})
export class PatientListService {

  constructor(private http:HttpClient) { }

  getPatients(){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': localStorage.getItem('token')
      })
    };
    var url = `${BASE_URL}/patients`;
    return this.http.get(url,httpOptions);
  }

  updatePatient(patientData){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': localStorage.getItem('token')
      })
    };
    var url = `${BASE_URL}/patients`;
    return this.http.put(url,patientData,httpOptions);
  }

  deletePatient(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': localStorage.getItem('token')
      })
    };
    var url = `${BASE_URL}/patients/${id}`;
    return this.http.delete(url,httpOptions);
  }
}
